import React from 'react';
import { Route, Routes, } from 'react-router-dom';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Conocenos from '../pages/Conocenos';
import Servicios from '../pages/Servicios';
import BlogSection from '../pages/BlogSection';
import BlogPost from '../pages/BlogPost';

function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/conocenos" element={<Conocenos />} />
      <Route path='/servicios' element={<Servicios/>}/>
      <Route path="/contact" element={<Contact />} />
      <Route path='/blogsection' element={<BlogSection/>}/>
      <Route path="/blog/:id" element={<BlogPost />} />
    </Routes>
  );
}

export default AppRouter;