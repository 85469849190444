// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content1">
        {/* Logo y enlaces */}
        <div className="footer-left">
          <img src={require('../assets/images/GafimexLogo.png')} className="footer-logo" />
          <nav className="footer-nav">
            <a href="/conocenos">Conócenos</a>
            <a href="/servicios">Servicios</a>
            <a href="/blogsection">Blog</a>
            <a href="https://www.facebook.com/profile.php?id=61565272042820" target='blank'><img src={require('../assets/images/Facebook.png')} alt="facebook"/></a>
            <a href="https://www.instagram.com/gafimex?igsh=anBhMDE1ZWwyamh0" target='blank' style={{marginLeft:"8px"}}><img src={require('../assets/images/Instagram.png')} alt="facebook"/></a>
          </nav>
          
        </div>

        {/* Sección de contacto */}
        
      </div>
      <div className='footer-content2'>
      <div className="footer-contact">
          <button className="contact-btn">Contactanos</button>
          <p>Email: info@positivus.com</p>
        </div>

        {/* Formulario de suscripción */}
        <div className="footer-subscribe">
          <input type="email" placeholder="Email" className="subscribe-input mr-1" />
          <button className="subscribe-btn">Suscríbete</button>
        </div>
      </div>

      {/* Sección de derechos reservados */}
      <div className="footer-bottom">
        <p>© 2024 Gafimex. All Rights Reserved.</p>
        <a href="#politicas" className="footer-privacy">Política de privacidad</a>
      </div>
    </footer>
  );
};

export default Footer;
