import React from 'react';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import img1 from '../assets/images/servicios/Graficas.png'
import img2 from '../assets/images/servicios/Equipo2.png'
import img3 from '../assets//images/servicios/OfficeMaterial.png'
import img4 from '../assets/images/servicios/Equipo1.png'
import img5 from '../assets/images/servicios/RepseLap.png'
import ContactForm from '../components/ContacForm';

function Home() {
    // Configuración del slider
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024, // Pantallas menores a 1024px
          settings: {
            slidesToShow: 1, // Mostrar 2 slides
            slidesToScroll: 1, // Desplazar 1 slide
          }
        },
        {
          breakpoint: 768, // Pantallas menores a 768px
          settings: {
            slidesToShow: 1, // Mostrar 1 slide
            slidesToScroll: 1, // Desplazar 1 slide
          }
        }
      ]
    };
    const testimonios = [
      {
        testimonio: "Trabajar con Gafimex ha sido una experiencia tranquila. Me gusta que siempre están disponibles cuando tengo alguna duda y que me ayudan a entender mejor mis números. No me siento perdida con la contabilidad como antes.",
        autor: "Mariana López"
      },
      {
        testimonio: "Conocí a Gafimex hace poco y desde entonces ha sido una gran ayuda para la administración de mi negocio. Su puntualidad y compromiso siempre han sido claves para mantener mis finanzas en orden.",
        autor: "John Carlos Ramírez"
      },
      {
        testimonio: "Gracias a Gafimex he podido reducir mis preocupaciones en temas fiscales. Siempre me explican todo detalladamente, lo que me permite tomar mejores decisiones para mi empresa.",
        autor: "Laura Martínez"
      }
    ];
  const servicios = [
    {
      titulo: 'Contabilidad',
      descripcion: 'Control, evaluación y estudio de todas las transacciones financieras: ingresos y egresos tanto de individuos como de empresas.',
      img: img1
    },
    {
      titulo: 'Impuestos',
      descripcion: 'Determinación y presentación de impuestos según la normativa fiscal vigente.',
      img: img2
    },
    {
      titulo: 'Nómina',
      descripcion: 'Elaboración, administración y revisión de la nómina de tus empleados, incluyendo cálculo de finiquitos.',
      img: img3
    },
    {
      titulo: 'Asesoría',
      descripcion: 'Asesoría financiera: análisis de inversiones, gestión de flujo de efectivo, planificación fiscal.',
      img: img4
    },
    {
      titulo: 'Repse',
      descripcion: 'Gestión integral para la inscripción, actualización y cumplimiento de obligaciones legales.',
      img: img5
    }
  ];
  return (
    <>
    <div className='hero-section'>
    <Container  style={{padding: "0em 6em"}}>
    <Row style={{justifyContent: 'center', alignItems:"center"}}>
        <Col sm='12' md='6'>
        <img src={require('../assets/images/Finanzas.png')} alt='finanzas' style={{width: '300px'}}/>
        </Col>
        <Col sm='12' md='6'>
         <h1>"Transparencia y confianza en cada balance"</h1>
         <p>En GAFIMEX, nos comprometemos a ser su aliado estratégico en el crecimiento y la estabilidad financiera de su empresa. Nuestra misión es proporcionarle la tranquilidad y seguridad de saber que su contabilidad está</p>
         <button className="contact-btn">Contactanos</button>
        </Col>
      </Row>

      <h2 className='subtitles-sections' style={{margin: '3em 0em'}}>Nuestros servicios</h2>
      
      <Row className='justify-content-center'>
        {servicios.map((servicio, index) => (
          <Col sm="12" md="4" key={index} className="mb-4 ">
            <div className="service-container text-center"> <h3 className="service-title">{servicio.titulo}</h3>
                <img src={servicio.img} alt={servicio.titulo} className='service-img-top'/>
                <p className='service-text'>{servicio.descripcion}</p></div>
              
              
                
             
          </Col>
        ))}
      </Row>
      
    </Container>

    </div>
    <div className='lines-section'>
    <Container style={{padding: "0em 6em"}}>
    <h2 className='subtitles-sections' style={{margin: '2em 0em '}}>Testimonios</h2>
      <div className="testimonios">
        
      <Slider {...sliderSettings}>
        {testimonios.map((item, index) => (
          <div key={index} >
            <div className="testimonio-card">
              <p className="testimonio-text">"{item.testimonio}"</p>
              
            </div>
            <p className="testimonio-author" style={{marginBottom: '4em'}}>{item.autor}</p>
          </div>
        ))}
      </Slider>
    
      </div>
    </Container>
      

      <ContactForm/>

    </div>
    
    </>
  );
}

export default Home;