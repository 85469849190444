// BlogCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ post }) => {
  return (
    <div className="blog-card">
      <img src={post.image} alt={post.title} className="blog-card-image" />
      <div className="blog-card-content">
        <p className="blog-card-date">{post.date}</p>
        <p className="blog-card-category">{post.category}</p>
        <h3 className="blog-card-title">
          <Link to={`/blog/${post.id}`}>{post.title}</Link>
        </h3>
      </div>
    </div>
  );
};

export default BlogCard;

