import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Col,
  Row,
  Container
} from 'reactstrap';

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formValues.name) formErrors.name = 'El nombre es requerido';
    if (!formValues.email) {
      formErrors.email = 'El correo es requerido';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      formErrors.email = 'El correo no es válido';
    }
    if (!formValues.phone) {
      formErrors.phone = 'El número de teléfono es requerido';
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      formErrors.phone = 'El número de teléfono debe tener 10 dígitos';
    }
    if (!formValues.message) formErrors.message = 'El mensaje es requerido';
    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      console.log('Form Submitted', formValues);
      // Aquí puedes manejar el envío de datos
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <Container style={{padding: "0em 6em", marginTop: '2em', marginBottom: '5em'}}>
      <h2 className="servicio-titulo">Contactanos</h2>
      <div className='form-container'>
      <h2>Enviar Mensaje</h2>
      <Row style={{alignItems:"center"}}>
        <Col xs='12' md='7'>
        <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="name">Nombre</Label>
          <Input
            type="text"
            name="name"
            id="name"
            value={formValues.name}
            onChange={handleChange}
            invalid={!!errors.name}
            placeholder="Nombre"
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="email">Email*</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={formValues.email}
            onChange={handleChange}
            invalid={!!errors.email}
            placeholder="Email"
          />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="phone">Número de teléfono*</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            value={formValues.phone}
            onChange={handleChange}
            invalid={!!errors.phone}
            placeholder="Teléfono"
          />
          <FormFeedback>{errors.phone}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="message">Mensaje</Label>
          <Input
            type="textarea"
            name="message"
            id="message"
            value={formValues.message}
            onChange={handleChange}
            invalid={!!errors.message}
            placeholder="Mensaje"
          />
          <FormFeedback>{errors.message}</FormFeedback>
        </FormGroup>

        <Button color="dark" block>
          Enviar Mensaje
        </Button>
      </Form>
        </Col>
        <Col xs='12' md='5' style={{display: 'flex', justifyContent:'flex-end', paddingRight: '-2em'}}>
        <img src={require('../assets/images/FondoForm.png')} alt="fondo" className='form-image'/>
        </Col>
      </Row>
        
      </div>
      
      
    </Container>
  );
};

export default ContactForm;

