import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ContactForm from '../components/ContacForm';

const Conocenos = () => {
  return (
    <>
    <div className='hero-section'>
    <Container style={{padding: "0em 6em", marginTop: '2em'}} >
        <Row>
          <Col xs='12' md='12'>
          <h1 className="text-center" > ¿Quiénes somos?</h1>
        <p style={{padding: '0em 4em', textAlign: 'justify', marginTop:'2em'}}>En GAFIMEX, desde 2010 brindamos servicios contables y financieros personalizados para pequeñas y medianas empresas en México. Combinamos tecnología avanzada y un equipo especializado para asegurar el cumplimiento fiscal y optimizar la rentabilidad. Nuestro objetivo es ser un aliado estratégico que impulse el crecimiento sostenible y competitivo de cada negocio con transparencia, excelencia y compromiso.</p>
          </Col>
        </Row>
        
        <Row className="mt-4" >
          <Col md="8">
            <h3>Misión</h3>
            <p>Impulsamos el crecimiento de las pymes con servicios contables y financieros personalizados, asegurando eficiencia, transparencia y confianza como socios estratégicos.</p>

            <h3>Visión</h3>
            <p>ISer el socio estratégico líder en servicios contables para pymes en México, promoviendo su crecimiento con excelencia, innovación tecnológica y compromiso con la calidad.</p>
          </Col>
          <Col md="4">
            <img src={require('../assets/images/Mision.png')} alt="Misión" className="img-fluid" />
          </Col>
        </Row>
        
      </Container>

      <Container style={{padding: "0em 6em", marginTop: '2em'}}>
        <Row>
          <Col xs="12" md="12">
          <img src={require('../assets/images/Equipo.png')} alt="Misión" className="img-fluid" />
          </Col>
        </Row>
      </Container>

    </div>
      
      

      {/* Valores Section */}
      <div className='lines-section'>
      <Container style={{padding: "0em 6em", marginTop: '2.5em'}}>
        <h2 className="text-center">Nuestros Valores</h2>
        <Row className="mt-4">
          <Col md="4" className="text-center">
            <h5>Integridad</h5>
            <p style={{padding: "0em 3em"}} > Actuar con ética y transparencia en todas las operaciones contables y financieras.</p>
          </Col>
          <Col md="4" className="text-center">
            <h5>Profesionalismo</h5>
            <p style={{padding: "0em 3em"}}>Mantener un alto nivel de competencia técnica y conocimiento actualizado.</p>
          </Col>
          <Col md="4" className="text-center">
            <h5>Confidencialidad</h5>
            <p style={{padding: "0em 3em"}}>Proteger la información financiera y personal de los clientes.</p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md="4" className="text-center">
            <h5>Responsabilidad</h5>
            <p style={{padding: "0em 3em"}}>Cumplir con todas las obligaciones fiscales y regulatorias de manera oportuna.</p>
          </Col>
          <Col md="4" className="text-center">
            <h5>Calidad</h5>
            <p style={{padding: "0em 3em"}}>Ofrecer servicios contables precisos y confiables.</p>
          </Col>
          <Col md="4" className="text-center">
            <h5>Compromiso</h5>
            <p style={{padding: "0em 3em"}}>Apoyar a los clientes en su crecimiento financiero con asesoramiento adecuado y proactivo.</p>
          </Col>
        </Row>
      </Container>
      <ContactForm/>
      </div>
      

      
    </>
  );
};

export default Conocenos;