// BlogSection.js
import React, { useState } from 'react';
import BlogCard from '../components/BlogCard';
import ContactForm from '../components/ContacForm';
import { Container } from 'reactstrap';

const BlogSection = () => {
  // Lista de posts de ejemplo
  const posts = [
    {
      id: 1,
      title: 'Catálogo de usos del CFDI 4.0 - Claves para facturar',
      date: '6 septiembre 2024',
      category: 'Obligaciones fiscales',
      image: require("../assets/images/blogs/imagen2.png"),
      link: '/blog/1',
    },
    {
      id: 2,
      title: 'Resolución Miscelánea Fiscal 2024',
      date: '4 septiembre 2024',
      category: 'Obligaciones fiscales',
      image: require("../assets/images/blogs/imagen3.png"),
      link: '/blog/2',
    },
    {
      id: 3,
      title: 'Navega correctamente entre las claves del CFDI',
      date: '2 septiembre 2024',
      category: 'Obligaciones fiscales',
      image: require("../assets/images/blogs/imagen 4.png"),
      link: '/blog/3',
    },
    {
      id: 4,
      title: 'Otra guía fiscal importante',
      date: '1 septiembre 2024',
      category: 'Obligaciones fiscales',
      image: 'image4.jpg',
      link: '/blog/4',
    },
    {
      id: 5,
      title: 'Nueva normativa fiscal 2024',
      date: '31 agosto 2024',
      category: 'Obligaciones fiscales',
      image: 'image5.jpg',
      link: '/blog/5',
    },
    {
      id: 6,
      title: 'Todo sobre facturación electrónica',
      date: '30 agosto 2024',
      category: 'Obligaciones fiscales',
      image: 'image6.jpg',
      link: '/blog/6',
    },
    // Más posts...
  ];

  // Estado para la paginación
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // Cálculo de los índices de los posts actuales
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Número total de páginas
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Función para cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
    <div className='hero-section'>
    <Container style={{padding: "0em 6em"}}>
    <h2 className="servicio-titulo">Blogs</h2>
    <div className="blog-section">
        {currentPosts.map((post) => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>

      {/* Paginación */}

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>

    </Container>

    </div>
    
      
      <ContactForm/>
    </>
  );
};

export default BlogSection;
