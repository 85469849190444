// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Navbar className='navbar-container' light expand="md" style={{padding:"1em 4em"}}>
      <NavbarBrand href='/'>
      <a className="navbar-brand" href="/"><img src={require('../assets/images/logoGafimex.png')} className="navbar-logo" /></a>

      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
      <Nav className="ml-auto custom-nav" navbar>
        
        <NavItem>
          <Link to="/conocenos" className="nav-link">Conócenos</Link>
        </NavItem>
        <NavItem>
          <Link to="/servicios" className="nav-link">Servicios</Link>
        </NavItem>
        <NavItem>
          <Link to="/blogsection" className="nav-link">Blog</Link>
        </NavItem>
      </Nav>
      </Collapse>
      
    </Navbar>
  );
};

export default Navigation;
