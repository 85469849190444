import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './routes/AppRouter';
import Footer from './components/Footer';
import NavBar from './components/Navbar';

function App() {
  return (
    <Router>
       <div className="app-wrapper">
      <NavBar/>
      <AppRouter />
      <Footer />
      </div>
    </Router>
  );
}

export default App;
