import React from 'react';
import { useParams } from 'react-router-dom';
import ContactForm from '../components/ContacForm';
import { Col, Container, Row } from 'reactstrap';

// Supongamos que este es el array de posts
const posts = [
  {
    id: 1,
    title: 'Declaracion de UMA',
    subtitle1: "¿Que es la UMA?",
    parrafo1: `De acuerdo con el Instituto Nacional de Estadística y Geografía
              (INEGI), la Unidad de Medida y Actualización (UMA) es la
              referencia económica en pesos mexicanos para determinar el pago de
              obligaciones y supuestos previstos en las leyes federales,
              entidades federativas y en disposiciones jurídicas que emanen de
              las anteriores.`,
    parrafo2: `La UMA viene siendo una medida relativamente nueva, pues se creó
              apenas en 2016 con la intención de sustituir el esquema de Veces
              Salario Mínimo (VSM) puesto que, al haber aumento en el salario
              mínimo, también subían las obligaciones fiscales.`,
    parrafo3:`Aunque en su momento la UMA tenía el mismo valor que el salario
              mínimo, conforme han pasado los años y se ha actualizado, han
              visto una diferencia uno y otro.`,
    subtitle2: '¿Como se calcula?',
    content: `La Unidad de Medida y Actualización llegó en una reforma que se
              hizo a la Constitución Política de los Estados Unidos Mexicanos
              (CPEUM) en cuanto a la desindexación del salario mínimo.
              En el artículo Segundo Transitorio del Decreto se estableció que
              el valor de la UMA debe ser actualizado cada año, trabajo que le
              toca hacer al INEGI.
              Una vez el INEGI tenga el valor de la UMA diario, mensual y anual,
              se publica en el Diario Oficial de la Federación (DOF) los
              primeros días de enero, pero entra en vigor hasta el 1 de febrero
              del mismo año.`,
  },
  {
    id: 2,
    title: 'Declaracion anual',
    subtitle1: "¿Como declarar?",
    parrafo1: `De acuerdo con el Instituto Nacional de Estadística y Geografía
              (INEGI), la Unidad de Medida y Actualización (UMA) es la
              referencia económica en pesos mexicanos para determinar el pago de
              obligaciones y supuestos previstos en las leyes federales,
              entidades federativas y en disposiciones jurídicas que emanen de
              las anteriores.`,
    parrafo2: `La UMA viene siendo una medida relativamente nueva, pues se creó
              apenas en 2016 con la intención de sustituir el esquema de Veces
              Salario Mínimo (VSM) puesto que, al haber aumento en el salario
              mínimo, también subían las obligaciones fiscales.`,
    parrafo3:`Aunque en su momento la UMA tenía el mismo valor que el salario
              mínimo, conforme han pasado los años y se ha actualizado, han
              visto una diferencia uno y otro.`,
    subtitle2: '¿Quien declara?',
    content: `La Unidad de Medida y Actualización llegó en una reforma que se
              hizo a la Constitución Política de los Estados Unidos Mexicanos
              (CPEUM) en cuanto a la desindexación del salario mínimo.
              En el artículo Segundo Transitorio del Decreto se estableció que
              el valor de la UMA debe ser actualizado cada año, trabajo que le
              toca hacer al INEGI.
              Una vez el INEGI tenga el valor de la UMA diario, mensual y anual,
              se publica en el Diario Oficial de la Federación (DOF) los
              primeros días de enero, pero entra en vigor hasta el 1 de febrero
              del mismo año.`,
  },
  {
    id: 3,
    title: 'Quienes no y quienes si',
    subtitle1: "¿Como declarar?",
    parrafo1: `De acuerdo con el Instituto Nacional de Estadística y Geografía
              (INEGI), la Unidad de Medida y Actualización (UMA) es la
              referencia económica en pesos mexicanos para determinar el pago de
              obligaciones y supuestos previstos en las leyes federales,
              entidades federativas y en disposiciones jurídicas que emanen de
              las anteriores.`,
    parrafo2: `La UMA viene siendo una medida relativamente nueva, pues se creó
              apenas en 2016 con la intención de sustituir el esquema de Veces
              Salario Mínimo (VSM) puesto que, al haber aumento en el salario
              mínimo, también subían las obligaciones fiscales.`,
    parrafo3:`Aunque en su momento la UMA tenía el mismo valor que el salario
              mínimo, conforme han pasado los años y se ha actualizado, han
              visto una diferencia uno y otro.`,
    subtitle2: '¿Quien declara?',
    content: `La Unidad de Medida y Actualización llegó en una reforma que se
              hizo a la Constitución Política de los Estados Unidos Mexicanos
              (CPEUM) en cuanto a la desindexación del salario mínimo.
              En el artículo Segundo Transitorio del Decreto se estableció que
              el valor de la UMA debe ser actualizado cada año, trabajo que le
              toca hacer al INEGI.
              Una vez el INEGI tenga el valor de la UMA diario, mensual y anual,
              se publica en el Diario Oficial de la Federación (DOF) los
              primeros días de enero, pero entra en vigor hasta el 1 de febrero
              del mismo año.`,
  },
];

const BlogPost = () => {
  const { id } = useParams(); // Obtener el id de la URL

  // Convertir el id a número porque useParams devuelve una cadena
  const postId = parseInt(id);

  // Buscar el post correspondiente usando el id
  const post = posts.find((p) => p.id === postId);

  // Si no se encuentra el post, mostramos un mensaje de error
  if (!post) {
    return <div>Post no encontrado</div>;
  }

  return (
    <>
      <Container
        className="bog-content"
        style={{ padding: "0em 6em", marginTop: "2em" }}
      >
        <h1 className="title-logs">{post.title}</h1>
        <Row>
          <Col sm="12" md="8">
            <h2>{post.subtitle1}</h2>
            <p>
              {post.parrafo1}
            </p>

            <p>
              {post.parrafo2}
            </p>

            <p>
              {post.parrafo3}
            </p>
          </Col>
          <Col sm="12" md="4" className="text-center">
            <img
              src={require("../assets/images/blogs/imagen2.png")}
              alt="uma"
              style={{
                width: "100%",
                maxWidth: "400px",
                borderRadius: "10px",
                height: "auto", // Para mantener las proporciones
              }}
            />
          </Col>
        </Row>

        <Row
          className="bog-content"
          style={{ marginTop: "4em", marginBottom: "5em" }}
        >
          <Col md="12">
            <h2>{post.subtitle2}</h2>
            <p>
              {post.content}
            </p>
            
          </Col>
        </Row>
      </Container>
      <ContactForm />
    </>
  );
};

export default BlogPost;
