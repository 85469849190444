import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ContactForm from '../components/ContacForm';
import img1 from '../assets/images/servicios/Graficas.png'
import img2 from '../assets/images/servicios/Equipo2.png'
import img3 from '../assets//images/servicios/OfficeMaterial.png'
import img4 from '../assets/images/servicios/Equipo1.png'
import img5 from '../assets/images/servicios/RepseLap.png'

const Servicios = () => {
  return (
    <>
    <div className='hero-section'>
    <Container style={{padding: "5em 6em"}}>
    {/* Servicio Contabilidad */}
    <Row className="servicio-item">
      <Col md="6" >
        <h2 className="servicio-titulo">Contabilidad</h2>
        <p>Registros contables de operaciones de ingresos y egresos.</p>
        <ul>
          <li>Pólizas de diario</li>
          <li>Pólizas de mayor.</li>
          <li>Balanza de comprobación. </li>
        </ul>
        <p>Elaboración de Estados Financieros Mensuales.</p>
        <ul>
          <li>Estados de situación financiera.</li>
          <li>Estado de resultado.</li>
          <li>Estado de flujo de efectivo. </li>
        </ul>
        <p>Control interno de operaciones.</p>
      </Col>
      <Col md="6">
        <img src={img1} alt="Contabilidad" className="servicio-imagen" />
      </Col>
    </Row>

    {/* Servicio Impuestos */}
    <Row className="servicio-item">
      <Col md="6" className="order-md-2 servicio-texto">
        <h2 className="servicio-titulo">Impuestos</h2>
        <p>Determinación y presentación de impuestos federal, estatal y Local.</p>
        <ul>
          <li>Impuesto estatal sobre nomina</li>
          <li>Impuesto sobre la renta</li>
          <li>Impuesto al valor agregado</li>
        </ul>
        <p>Regularización Contable y Fiscal de Contribuyentes.</p>
        <p>Asesoría continua para la resolución de problemas o dudas.</p>
        <p>Gestiones ante organismos gubernamentales, como son locales, estatales y federales.</p>
        <p>Determinación y presentación de cuota obrero patronal.</p>
      </Col>
      <Col md="6" className="order-md-1">
        <img src={img2} alt="Impuestos" className="servicio-imagen" />
      </Col>
    </Row>

  </Container>
    </div>
    
  <div className='lines-section'>
  <Container style={{padding: "0em 6em"}}>
      

      {/* Servicio Nómina */}
      <Row className="servicio-item">
        <Col md="6" className="servicio-texto">
          <h2 className="servicio-titulo">Nómina</h2>
          <p>Manejo de nóminas semanales, catorcenales, quincenales, mensuales, destajo.</p>
          <p>cálculo de provisiones como: aguinaldo, vacaciones, prima vacacional, cuotas IMSS.</p>
          <p>Reporte de nómina en formato PDF y EXCEL.</p>
          <p>Generación de recibos CFDI´s</p>
          <p>Cálculo de diversas versiones de Finiquitos.</p>
          <p>Proyección de finiquitos masivos por tipos de nómina, centros de costos, puestos, tipo de empleado</p>
          <p>Impresión de Finiquito y carta Renuncia.</p>
          <p>Cálculo e Impresión de Nómina de Finiquito.</p>
          <p>Creación de Recibos de Pago para el Timbrado ante el SAT.</p>
        </Col>
        <Col md="6">
          <img src={img3} alt="Nómina" className="servicio-imagen" />
        </Col>
      </Row>

      {/* Servicio Asesoría */}
      <Row className="servicio-item">
        <Col md="6" className="order-md-2 servicio-texto">
          <h2 className="servicio-titulo">Asesoría</h2>
          <p>Proyección de Estatutos.</p>
          <p>Proyección de Actas de Asamblea.</p>
          <p>Diseño de Contratos Civiles, Mercantiles, Laborales, etc.</p>
          <p>Constitución de Sociedades Mercantiles.</p>
          <p>Constitución de Asociaciones.</p>
          <p>Sociedad Conyugal y Copropiedad.</p>
          <p>Fusiones y Escisiones</p>
          <p>Asesoría en Materia Fiscal, y Trámites Fiscales.</p>
          <p>Asesoría en imagen Corporativa.</p>
        </Col>
        <Col md="6" className="order-md-1">
          <img src={img4} alt="Asesoría" className="servicio-imagen" />
        </Col>
      </Row>

      {/* Servicio REPSE */}
      <Row className="servicio-item">
        <Col md="6" className="servicio-texto">
          <h2 className="servicio-titulo">REPSE</h2>
          <p>Asesoría en alta o renovación de Registro de Prestadoras de Servicios Especializados y Obras Especializada.</p>
          <p>Formulación y presentación de SISUB INFONAVIT</p>
          <p>Formulación y presentación de ICSOE IMSS</p>
          <p>Monitoreo y Cumplimiento Continuo.</p>
        </Col>
        <Col md="6" className='text-center'>
          <img src={img5} alt="REPSE" className="servicio-imagen" />
        </Col>
      </Row>
    </Container>
    <ContactForm/>

  </div>
  

    
    </>
    
  );
};

export default Servicios;
